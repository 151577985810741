import {defineStore} from 'pinia'
import {mainAxios} from "@/main";
import {useApiSettingStore} from "@/stores/apiSettings";

export const useSettings = defineStore({
    id: 'settings',

    state: () => ({
        showFakeComponent: false,
        loading: null,
        checkAudioStatus: false,
        messages: [],
        addSetting: false,
        settings: null,
        systemSettings: {},
        serviceAccountCredentials: {},
        gender: null,
        voice: null,
        setting: null,
        optionsSettings: null,
        isGenderModified: false
    }),
    actions: {
        editSetting(payload) {
            return new Promise((resolve, reject) => {
                payload = payload.queryParams;
                try {
                    mainAxios.patch('api/settings/' + payload.id + '/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                } catch (e) {
                    //
                }
            })
        },
        getServiceAccountCredentials() {
            return new Promise((resolve, reject) => {
                let params = new URLSearchParams()
                params.append('key__in', 'service_account_chat')
                mainAxios.get('api/settings/?' + params)
                    .then(res => {
                        if (res.data.results.length > 0) {
                            this.serviceAccountCredentials = res.data.results[0]
                            let settings_records = {}
                            res.data.results[0].setting_records.forEach(setting => {
                                settings_records[setting.key] = {
                                    "value": setting.value,
                                    "choices": setting.choices ? setting.choices.choices : null
                                }
                            })
                            this.serviceAccountCredentials['setting_records'] = settings_records

                                    console.log("setting",settings_records);
                        }
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getSettings(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(mainAxios.defaults.baseURL + 'api/settings/')

                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    params.set('key__in', 'avatar_settings')
                    url.search = params
                    mainAxios.get(url.pathname + url.search)
                        .then(res => {
                            if ('key' in payload.queryParams) { // and payload.queryParams.key === 'avatar_settings'
                                res.data.results[0].setting_records.forEach(setting => {
                                    this.systemSettings[setting.key] = {
                                        "value": setting.value,
                                        "choices": setting.choices ? setting.choices.choices : null
                                    }
                                })
                                if (!this.isGenderModified) {
                                    this.gender = useSettings().systemSettingsGetters.avatar_gender_choice.value;
                                    this.voice = useSettings().systemSettingsGetters.avatar_voice_choice.value
                                }
                            }
                            this.settings = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    let params = new URLSearchParams()
                    params.set('key__in', 'avatar_settings')
                    url.search = params
                    mainAxios.get(url.pathname + url.search)
                        .then(res => {
                            res.data.results[0].setting_records.forEach(setting => {
                                this.systemSettings[setting.key] = {
                                    "value": setting.value,
                                    "choices": setting.choices ? setting.choices.choices : null
                                }
                            })
                            if (!this.isGenderModified) {
                                this.gender = useSettings().systemSettingsGetters.avatar_gender_choice.value;
                                this.voice = useSettings().systemSettingsGetters.avatar_voice_choice.value
                            }
                            this.settings = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        },
        getSetting(payload) {
            return new Promise((resolve, reject) => {
                mainAxios.get('api/settings/' + payload + '/')
                    .then(res => {
                        this.setting = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getOptionsSettings() {
            return new Promise((resolve, reject) => {
                mainAxios.options('api/settings/')
                    .then(res => {
                        this.optionsSettings = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        createTimeline() {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                // mainAxios.post('api/forms/', payload, config)
                //     .then(res => {
                //         resolve(res)
                //     })
                //     .catch(err => {
                //         if (err.response && err.response.status === 500) {
                //             useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                //         }
                //         reject(err)
                //     })
            })
        },
    },

    getters: {
        settingsGetters: state => {
            return state.settings
        },
        settingGetters: state => {
            return state.setting
        },
        optionsSettingsGetters: state => {
            return state.optionsSettings
        },
        addSettingGetters: state => {
            return state.addSetting
        },
        loadingGetters: state => {
            return state.loading
        },
        showFakeComponentGetters: state => {
            return state.showFakeComponent
        },
        messagesGetters: state => {
            return state.messages
        },
        checkAudioStatusGetters: state => {
            return state.checkAudioStatus
        },
        systemSettingsGetters: state => {
            return state.systemSettings
        },
        genderGetters: state => {
            return state.gender
        },
        voiceGetters: state => {
            return state.voice
        },
        isGenderModifiedGetters: state => {
            return state.isGenderModified
        },
        serviceAccountCredentialsGetters: state => {
            return state.serviceAccountCredentials
        }
    }
})


